<template>
  <div>
    <div v-html="lesson.content"></div>
  </div>
</template>

<script>
export default {
  name: 'Lesson',
  data() {
    return {
      lesson: {
        name: '',
        content: '',
      },
    }
  },
  mounted() {
    this.getChapter(this.$route.params.uuid)
  },
  methods: {
    async getChapter(uuid) {
      await this.$services.ApiServices.get('chapter', uuid).then(
        ({ data }) => {
          this.lesson = data.data.details
        },
        err => console.error(err),
      )
    },
  },
}
</script>

<style>
</style>
