<template>
  <div>
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>

    <div class="mb-8 mt-5">
      <div class="d-flex align-md-center">
        <div class="mr-4">
          <v-img
            width="50"
            :src="chapter.icon.icon"
          ></v-img>
        </div>
        <h3 class="font-weight-bold black--text">
          {{ chapter.chapter_of }} <span>. {{ chapter.name }}</span>
        </h3>
      </div>
    </div>
    <v-card>
      <v-tabs
        v-model="tab"
        show-arrows
        fixed-tabs
      >
        <v-tab
          v-for="tabItem in tabs"
          :key="tabItem.title"
        >
          <span>{{ tabItem.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="tab"
        class="pa-6"
      >
        <v-tab-item>
          <lesson-detail></lesson-detail>
        </v-tab-item>
        <v-tab-item>
          <lesson-comment></lesson-comment>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mdiArrowLeft, mdiNoteOutline, mdiPlus } from '@mdi/js'
import LessonComment from './lesson/LessonComment.vue'
import LessonDetail from './lesson/LessonDetail.vue'

export default {
  name: 'ElearningLesson',
  components: {
    LessonDetail,
    LessonComment,
  },
  data() {
    return {
      tab: '',
      tabs: [{ title: 'Materi' }, { title: 'Komentar' }],
      icons: {
        mdiArrowLeft,
        mdiNoteOutline,
        mdiPlus,
      },
      chapter: {
        chapter_of: '',
        lesson: {
          name: '',
          content: '',
        },
        icon: {
          icon: '',
        },
      },
      status: true,
      is_comment: 0,
    }
  },
  mounted() {
    this.getChapter(this.$route.params.uuid)
  },
  methods: {
    returnBack() {
      return this.$router.back()
    },
    async getChapter(uuid) {
      await this.$services.ApiServices.get('chapter', uuid).then(
        ({ data }) => {
          this.chapter = data.data
          this.is_comment = data.data.details.is_comment

          if (this.is_comment === 'Allowed') {
            this.tabs = [{ title: 'Materi' }, { title: 'Komentar' }]
          } else {
            this.tabs = [{ title: 'Materi' }]
          }
          // eslint-disable-next-line prefer-destructuring
        },
        err => console.error(err),
      )
    },
  },
}
</script>

<style>
</style>
