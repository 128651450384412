<template>
  <div>
    <div class="mb-4">
      <h2 class="font-weight-bold black--text">
        Komentar . <span class="text-subtitle-2">{{ comments.length }} Komentar</span>
      </h2>
    </div>
    <div class="d-flex align-center mb-8">
      <div class="mr-4">
        <v-avatar v-if="userDetail.details[0].image !== null">
          <v-img :src="userDetail.details[0].image"></v-img>
        </v-avatar>
        <v-avatar v-else>
          <v-img src="@/assets/images/avatars/ari.png"></v-img>
        </v-avatar>
      </div>
      <div>
        <h3 class="font-weight-bold black--text">
          Tambahkan Komentar
        </h3>
        <div>
          <v-text-field
            v-model="commentLesson.description"
            label="Tulis Komentar..."
          ></v-text-field>
        </div>
        <v-btn
          class="mr-2"
          color="primary"
          :loading="isLoadingButton"
          @click.prevent="addComment"
        >
          Kirim
        </v-btn>
        <v-btn
          color="primary"
          outlined
        >
          Batal
        </v-btn>
      </div>
    </div>
    <div
      v-for="comment in comments"
      :key="comment.uuid"
    >
      <div class="d-flex mb-4">
        <div class="mr-4">
          <v-avatar v-if="comment.user.details[0].image !== null">
            <v-img :src="comment.user.details[0].image"></v-img>
          </v-avatar>
          <v-avatar v-else>
            <v-img src="@/assets/images/avatars/ari.png"></v-img>
          </v-avatar>
        </div>
        <div>
          <div class="mb-4">
            <h5 class="font-weight-bold black--text mb-2">
              {{ comment.user.name }}
            </h5>
            <p class="mb-2">
              {{ comment.description }}
            </p>
            <small>{{ getFormatDate(comment.time) }}</small>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div
          class="hidden-element mr-4"
        >
          <v-avatar class="hidden-avatar">
            <v-img
              src="../../../../assets/images/avatars/ari.png"
            ></v-img>
          </v-avatar>
        </div>
        <v-row>
          <v-col
            cols="12"
          >
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiChevronDown } from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiChevronDown,
      },
      comments: [],
      commentLesson: {
        description: '',
      },
      isLoadingButton: false,
      user: {},
      userDetail: JSON.parse(localStorage.getItem('user')).user,
    }
  },
  async mounted() {
    await this.getChapter()
    this.getCommentLesson()
  },
  methods: {
    async getChapter() {
      await this.$services.ApiServices.get('chapter', this.$route.params.uuid).then(
        ({ data }) => {
          this.lesson = data.data.details
        },
        err => console.error(err),
      )
    },
    async getCommentLesson() {
      await this.$services.ApiServices.get('commentlesson', this.lesson.uuid).then(
        ({ data }) => {
          this.comments = data.data
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
    },
    async addComment() {
      this.isLoadingButton = true

      this.commentLesson.lesson_uuid = this.lesson.uuid
      this.commentLesson.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
      await this.$services.ApiServices.add('commentlesson', this.commentLesson).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.commentLesson = {
            description: '',
          }

          this.isLoadingButton = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.getCommentLesson()
    },
    getFormatDate(date) {
      const createdDate = new Date(date)
      const day = `0${createdDate.getDate()}`
      const month = `0${createdDate.getMonth() + 1}` // months are zero based
      const year = createdDate.getFullYear()

      const time = createdDate.toLocaleTimeString().replace(/(.*)\D\d+/, '$1')

      return `${time}, ${year}/${month.slice(-2)}/${day.slice(-2)}`
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.hidden-element {
  visibility: hidden;
}
.hidden-avatar {
  height: 30px !important;
}
</style>
